<template>
    <div :class="cn('relative rounded-md bg-[#f5f5f5] dark:bg-[#202226] mt-[5px] transition-transform hover:translate-y-[-5px] pt-8 pb-4 px-4', $attrs.class!)" v-bind="$attrs">
        <ShadBadge v-if="$dayjs(date).isAfter($dayjs().subtract(10, 'day')) && $dayjs(date).isBefore($dayjs())" variant="success" class="absolute top-2.5 left-2.5">New</ShadBadge>
        <NuxtImg class="mx-auto object-contain h-24" :src="icon" :alt="name" loading="lazy" />
        <div class="mt-6">
            <span class="text-xs font-medium text-gray-800 dark:text-white" v-if="category">{{ category }}</span>
            <h2 class="font-bold text-gray-800 dark:text-white truncate">{{ name }}</h2>
        </div>
        <div class="grid grid-flow-col auto-cols-auto font-semibold text-gray-800 dark:text-white text-sm mt-2">
            <div class="text-left">
                <span>Value</span>
            </div>
            <div class="text-right">
                <span>{{ value != undefined ? formatNumber(value) : 'N/A' }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { cn } from '@/lib/utils'

defineProps({
    icon: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true
    },
    category: String,
    value: Number,
    date: String
})

defineOptions({
    inheritAttrs: false
})
</script>